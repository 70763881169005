import React from 'react'
import Layout from '../components/layouts'
import HeadMeta from '../components/HeadMeta'
import * as ds from '../uiLibrary'
import { GatsbyHeroImage } from '../components/heroes'
import {
  GatsbyImageSection,
  TableComponent,
  InfoBulletContainerSection,
  InfoContainerSection,
  GoldBox,
  TriangleDiv,
  IconSection,
  Video,
  AngledButton,
} from '../components'
import { Headline, H2, H4, PLarge } from '../uiLibrary/Typographyv2'
import { StaticQuery, graphql } from 'gatsby'
import { LocationContext } from '../pages/index'
import { useLocale } from '../hooks/useLocale'
import { Banner } from '../components/Banner'

export const FullThrottle = ({ data, ...props }) => {
  const page = data.allFullThrottleJson.edges[0].node
  const bannerImage = data.banner.childImageSharp
  const imageSectionData = data.sections.edges.map(({ node }, i) => {
    return { image: node.childImageSharp, ...page.imageSections[i] }
  })

  const currentLocale = useLocale(props.location.pathname)

  const switchImageSections = (sec, i, terms) => {
    switch (i) {
      case 0:
        return (
          <>
            <GatsbyImageSection
              key={sec.image.id}
              url={sec.image.fluid}
              {...sec}
            />
            <ds.FixedWidthRow
              py={0}
              bg={ds.brandColors.bg.black}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              style={{ maxWidth: '1200px' }}
            >
              <a
                style={{ textDecoration: 'none' }}
                rel="noopener noreferrer"
                href="https://www.tonit.com/terms/#section12"
                target="_blank"
              >
                <PLarge
                  color={ds.brandColors.typography.white}
                  fontWeight={600}
                  fontSize={ds.typeScale.t3}
                  style={{
                    textTransform: 'uppercase',
                    textDecoration: 'underline',
                  }}
                >
                  {terms}
                </PLarge>
              </a>
            </ds.FixedWidthRow>
          </>
        )

      case 1:
        return (
          <React.Fragment key={sec.image.id}>
            <GatsbyImageSection
              key={sec.image.id}
              url={sec.image.fluid}
              {...sec}
            />
            {page.features.map((f, i) => {
              return (
                <InfoBulletContainerSection
                  key={f.header}
                  {...f}
                  image={f.image}
                  pt={ds.spacing.s5}
                  pb={ds.spacing.s6}
                  index={i}
                />
              )
            })}
          </React.Fragment>
        )
      case 2:
        return (
          <React.Fragment key={sec.image.id}>
            <GatsbyImageSection
              key={sec.image.id}
              url={sec.image.fluid}
              {...sec}
            />
            <TableComponent data={page.ftTableData} />
          </React.Fragment>
        )

      default:
        break
    }
  }

  // v2 banner doesn't have embed text in image
  const BannerImage = page.bannerHeadline ? Banner : GatsbyHeroImage

  return (
    <LocationContext.Provider value={props.location}>
      <Layout locale={currentLocale}>
        <HeadMeta
          pageTitle={page.pageTitle || 'Tonit'}
          pageDescription={page.pageDescription}
          socialTitle={page.socialTitle}
          socialDescription={page.socialDescription}
          socialImage={page.socialImage}
        />
        <ds.Background background={'#000000'}>
          <ds.FixedWidthContainer
            py={[ds.spacing.s5, ds.spacing.s6, ds.spacing.s6]}
          >
            <BannerImage
              url={bannerImage.fluid}
              headline={page.bannerHeadline}
              accent={ds.brandColors.mediumGold}
            />
            <Headline
              textAlign={'center'}
              px={[ds.spacing.s3, ds.spacing.s4, ds.spacing.s5]}
              color={'#B39151'}
              fontSize={[ds.typeScale.t6, ds.typeScale.t8, '4.998rem']}
            >
              {page.heroHeading}
            </Headline>
            <H2
              color={ds.brandColors.typography.light}
              textAlign={['center', 'center']}
              px={[ds.spacing.s3, ds.spacing.s4, ds.spacing.s5]}
              fontSize={[ds.typeScale.t5, ds.typeScale.t7]}
            >
              {page.heroSubheading}
            </H2>
            <ds.StyledHeaderCtaDiv py={[0, ds.spacing.s5]}>
              <AngledButton
                href={`https://tonitmedia.app.link/fullthrottlesubscription`}
                bg={ds.brandColors.darkGold}
                focus={ds.brandColors.lightGold}
                label={page.bannerCtaMessage}
              />
            </ds.StyledHeaderCtaDiv>
          </ds.FixedWidthContainer>
        </ds.Background>
        <ds.Background background={'#202020'} pb={[ds.spacing.s5]}>
          <ds.FixedWidthContainer bg="white" py={[ds.spacing.s5]}>
            <TriangleDiv display={['none', 'none', 'block']} />
            <ds.FixedWidthRow py={0}>
              <ds.Column
                span={12}
                display="flex"
                justifyContent="center"
                textAlign="center"
              >
                <H2
                  color={ds.brandColors.darkGold}
                  fontSize={[ds.typeScale.t7, ds.typeScale.t7, ds.typeScale.t8]}
                >
                  {page.video.introMessage}
                </H2>
              </ds.Column>
              <ds.Column span={12} display="flex" justifyContent="center">
                {/* '?rel=0' required addition to force related videos to be TONIT content */}
                <Video
                  videoSrcUrl={`${page.video.srcUrl}${'?rel=0'}`}
                  videoTitle={page.video.title}
                />
              </ds.Column>
            </ds.FixedWidthRow>
          </ds.FixedWidthContainer>
          <ds.FixedWidthContainer bg="white" py={[ds.spacing.s4]}>
            <ds.FixedWidthRow
              py={0}
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <ds.Column span={12}>
                <H2
                  textAlign="center"
                  color={page.introSection.titleColor}
                  fontSize={[ds.typeScale.t6, ds.typeScale.t6, ds.typeScale.t7]}
                >
                  {page.introSection.title}
                </H2>
              </ds.Column>
            </ds.FixedWidthRow>
          </ds.FixedWidthContainer>
          <ds.FixedWidthContainer bg="white" pb={[ds.spacing.s5]}>
            <ds.FixedWidthRow
              py={0}
              display="flex"
              flexDirection="row"
              justifyContent="space-evenly"
              style={{ flexWrap: 'wrap' }}
            >
              {page.pricingStructure.map((p, i) => {
                return (
                  <ds.Column
                    key={`${p.price}${i}`}
                    span={6}
                    alignItems="center"
                    py={ds.spacing.s3}
                  >
                    <GoldBox
                      price={p.price}
                      duration={p.duration}
                      note={p.note}
                    />
                  </ds.Column>
                )
              })}
            </ds.FixedWidthRow>
            <ds.FixedWidthRow
              pt={ds.spacing.s5}
              pb={0}
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <ds.Column span={12} alignItems="center">
                <AngledButton
                  href={`https://tonitmedia.app.link/fullthrottlesubscription`}
                  bg={ds.brandColors.darkGold}
                  focus={ds.brandColors.lightGold}
                  label={page.bannerCtaMessage}
                />
              </ds.Column>
            </ds.FixedWidthRow>
            <ds.FixedWidthRow
              py={ds.spacing.s5}
              px={[ds.spacing.s3, ds.spacing.s4, ds.spacing.s6]}
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="center"
              alignItems="center"
            >
              <ds.Column>
                <H4
                  fontSize={[ds.typeScale.t5, ds.typeScale.t6]}
                  my={ds.spacing.s2}
                  textAlign={'center'}
                  alignSelf={'center'}
                  color={page.introSection.bodyColor}
                >
                  {page.introSection.body1}
                </H4>
              </ds.Column>
            </ds.FixedWidthRow>
            {page.overview.map((sec, index) => {
              return (
                <ds.FixedWidthRow py={0} key={index}>
                  <ds.Column span={12}>
                    <IconSection key={`${sec.title}${index}`} {...sec} />
                  </ds.Column>
                </ds.FixedWidthRow>
              )
            })}
          </ds.FixedWidthContainer>
          {imageSectionData.map((sec, i) =>
            switchImageSections(sec, i, page.termsDisclaimer)
          )}
          <InfoContainerSection
            {...page.thirdSection}
            image={data.FtLogo.edges[0].node.childImageSharp.fluid}
            pb={ds.spacing.s6}
            pt={[ds.spacing.s5]}
            index={0}
          />
          <ds.FixedWidthContainer bg="#000" pb={ds.spacing.s6}>
            <ds.FixedWidthRow
              py={0}
              display="flex"
              flexDirection="row"
              justifyContent="center"
            >
              <a
                style={{ textDecoration: 'none' }}
                rel="noopener noreferrer"
                href="https://www.tonit.com/terms/#section9"
                target="_blank"
              >
                <PLarge
                  color={ds.brandColors.typography.white}
                  fontWeight={600}
                  fontSize={ds.typeScale.t3}
                  style={{
                    textTransform: 'uppercase',
                    textDecoration: 'underline',
                  }}
                >
                  {page.termsDisclaimer}
                </PLarge>
              </a>
            </ds.FixedWidthRow>
          </ds.FixedWidthContainer>
        </ds.Background>
      </Layout>
    </LocationContext.Provider>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        banner: file(relativePath: { eq: "tonitfullthrottleheader.png" }) {
          childImageSharp {
            fluid(maxWidth: 1440) {
              aspectRatio
              base64
              sizes
              src
              srcSet
            }
          }
        }
        screenshots: allFile(
          filter: { name: { regex: "/^fullThrottleScreenshot*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fixed(width: 300) {
                  ...GatsbyImageSharpFixed
                  height
                  width
                }
              }
            }
          }
        }
        FtLogo: allFile(
          filter: { name: { regex: "/^Full-Throttle-Logo*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 400) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        sections: allFile(
          filter: { name: { regex: "/^fullthrottle*/" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              childImageSharp {
                id
                fluid(maxWidth: 1200, quality: 100) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
        allFullThrottleJson {
          edges {
            node {
              bannerHeadline
              heroHeading
              heroSubheading
              heroButton
              bannerCtaMessage
              pricingStructure {
                price
                duration
                note
              }
              overview {
                title
                description
                icon
                iconAlt
              }
              introSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                order
                textAlign
                imageAlt
                imageExists
              }
              secondarySection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                order
                textAlign
                imageAlt
                imageExists
              }
              thirdSection {
                title
                titleColor
                header
                headerColor
                bodyColor
                body1
                body2
                body3
                button
                storeButton
                buttonLink
                buttonLabel
                buttonColor
                buttonFocus
                triangle
                bg
                order
                textAlign
                imageAlt
                imageExists
              }
              features {
                title
                titleColor
                header
                headerColor
                body1
                body2
                body3
                bullets
                bodyColor
                textAlign
                imageAlt
                image
                order
                triangle
                bg
              }
              ftTableData {
                tableHeader
                tableFeatures {
                  title
                  features {
                    description
                    basic
                    fullThrottle
                  }
                }
              }
              imageSections {
                containerColor
                urlDescription
                flip
                reverse
                button
                buttonLink
                buttonLabel
                buttonColor
                focus
                src
                box
                srcDescription
                titleColor
                title
                firstBody
                secondBody
                thirdBody
                storeButton
              }
              video {
                introMessage
                title
                srcUrl
              }
              pageTitle
              pageDescription
              socialTitle
              socialDescription
              socialImage
              termsDisclaimer
            }
          }
        }
      }
    `}
    render={data => <FullThrottle data={data} {...props} />}
  />
)
